// 引入封装的axios请求文件
import req from '../utils/request.js'
// 搜索Tracking id
export function searchTarckingId (params) {
  return req.post('tracking', params)
}
// 公共配置
export function commmonInfo (params) {
  return req.post('config', params)
}
// work bench
export function workbench (params) {
  return req.post('workbench', params)
}

// 报价
export function onlyQuote (params) {
  return req.post('onlyQuote', params)
}

// 统计表
export function statisticsOrder (params) {
  return req.post('statisticsOrder', params)
}

// getToZipsUnderRange
export function getToZipsUnderRange (params) {
  return req.post('getToZipsUnderRange', params)
}

// getFromZipsUnderRange
export function getFromZipsUnderRange (params) {
  return req.post('getFromZipsUnderRange', params)
}

// contact us
export function contact (params) {
  return req.post('contact_us', params)
}
// 添加备注
export function addNote (params) {
  return req.post('addNote', params)
}

// Basecharge 表格
export function activeBaseCharges (params) {
  return req.post('activeBaseCharges', params)
}

// fuel 表格
export function fuelPercentList (params) {
  return req.post('fuelPercentList', params)
}

// 附加费
export function additionalHandlingFees (params) {
  return req.post('additionalHandlingFees', params)
}

// blog 列表
export function articleLists (params) {
  return req.post('articleLists', params)
}

// blog 详情
export function articleDetail (params) {
  return req.post('articleDetail', params)
}
