<template>
  <div class="topNav">
    <img src="../../assets/images/home/Frame.svg" alt="">
    <div>
      <a-button type="primary" shape="round" @click="$router.push('/register')">Sign up</a-button>
    </div>
  </div>
  <div class="login-cont">
    <!-- <div> -->
    <img src="../../assets/images/login/logo.svg" alt="">
    <!-- </div> -->
    <div class="login-title">Log In</div>
    <div class="login-form">
      <a-form ref="formRef" :model="formLogin" layout="vertical" @submitSuccess="validateSuccess" autocomplete="off">
        <a-form-item field="email" label="User ID" :rules="loginRules.email">
          <a-input v-model="formLogin.email" allow-clear autocomplete="off" />
        </a-form-item>
        <a-form-item field="password" label="Password" :rules="loginRules.password">
          <a-input-password id="pwd" autocomplete="current-password" v-model="formLogin.password"
            :readonly="passwordReadonly" @mousedown="passwordClick" @blur="passwordBlur" @focus="passwordFocus"
            @input="passwordChange" allow-clear />
        </a-form-item>
        <div class="footer-cont">
          <a-checkbox v-model="isRember">Remember My User ID</a-checkbox>
        </div>
        <div class="code-btn">
          <a-button type="primary" html-type="submit" :loading="loginLoading">Log in</a-button>
        </div>
        <div class="code-btn">
          <a-button type="text" status="success" @click="$router.push('/resetpwd')">
            Forget the password?
          </a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
// 引入api中的接口
import { login, userInfo, mangeLogin } from '@/api/account.js'
import { commmonInfo } from '@/api/homepage.js'
import { Message } from '@arco-design/web-vue'
import local from '@/utils/local.js'
const formRef = ref(null)
const router = useRouter()
const route = useRoute()
const formLogin = reactive({
  email: '',
  password: ''
})
const passwordReadonly = ref(true)
// 聚焦时取消只读
const passwordFocus = () => {
  // console.log('聚焦时取消只读')
  setTimeout(() => {
    passwordReadonly.value = false
  })
}
// 失焦时设置只读
const passwordBlur = () => {
  // console.log('失焦时设置只读')
  passwordReadonly.value = true
}
// 重复点击禁止出现弹出选择
const passwordClick = () => {
  // console.log('重复点击禁止出现弹出选择')
  passwordBlur()
  passwordFocus()
}
// 删除完密码阻止弹出选择，change事件
const passwordChange = () => {
  // console.log('删除完密码阻止弹出选择')
  if (formLogin.password.length === 0) {
    passwordBlur()
    passwordFocus()
  }
}
// const clickpwd = () => {
//   document.getElementById('pwd').getElementsByTagName('input')[0].removeAttribute('readonly')
// }
// const blurpwd = () => {
//   document.getElementById('pwd').getElementsByTagName('input')[0].setAttribute('readonly', true)
// }
const isRember = ref(false)
// 自定义验证
const loginRules = {
  email: [
    {
      validator: (value, cd) => {
        const emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/ // eslint-disable-line no-useless-escape
        if (!value) {
          cd(new Error('Please enter email.').message)
        } else if (!emailReg.test(value)) {
          cd(new Error('Please enter correct email format.').message)
        } else {
          cd()
        }
      }
    }
  ],
  password: [
    {
      validator: (value, cd) => {
        // const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]).{8,}$/ // eslint-disable-line no-useless-escape
        if (!value) {
          cd(new Error('Please enter password.').message)
        } else {
          cd()
        }
      }
    }
  ]
}
const loginLoading = ref(false)
async function validateSuccess () {
  loginLoading.value = true
  const msg = await login({
    email: formLogin.email,
    password: formLogin.password
  })
  if (msg.code === 0) {
    loginLoading.value = false
    if (isRember.value === true) {
      local.set('use', formLogin.email)
    } else {
      local.del('use')
    }
    local.set('token', msg.data.token)
    local.set('sws', msg.data.sws)
    // 获取用户信息，存在localStorage
    getUserInfo()
  } else if (msg.code === 5) {
    loginLoading.value = false
    Message.error(msg.message)
  } else {
    loginLoading.value = false
    Message.error('The account or password is incorrect.')
  }
}
// 获取用户信息
async function getUserInfo () {
  const msg = await userInfo({})
  if (msg.code === 0) {
    router.push({
      path: msg.data.login_directly_to,
      query: { k: msg.data.key }
    })
    local.set('userInfo', JSON.stringify(msg.data))
  }
}
if (local.get('use')) {
  formLogin.email = local.get('use')
}
// 公共信息
async function getConfig () {
  const data = await commmonInfo()
  local.set('configInfo', JSON.stringify(data.data))
}
// 调用获取公共信息
getConfig()

// 管理员登录
const mloagin = async () => {
  const msg = await mangeLogin({
    m: route.query.m,
    t: route.query.t
  })
  if (msg.code === 0) {
    local.set('token', msg.data.token)
    local.set('sws', msg.data.sws)
    // 获取用户信息，存在localStorage
    getUserInfo()
  } else {
    Message.error(msg.message)
  }
}
if (route.query.m && route.query.t) {
  mloagin()
}
</script>

<style lang="less" scoped>
.topNav {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 0 82px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.login-cont {
  text-align: center;
  margin-top: 80px;
}

.login-title {
  color: var(--color-text-1);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 40px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  .arco-form {
    width: 300px;
  }
}

.footer-cont {
  margin-top: 40px;
}

.code-btn {
  margin-top: 20px;
}
</style>
